
import { Vue, Component, Prop } from 'vue-property-decorator'

import $app from '@/plugins/modules'
import { Common } from '@/common'
import { System } from '@/modules/system'
import { IViewAction, ITableHeader, IPagination, Allign, ActionStyle, ISearch } from '@/modules/system/types'

@Component
export default class BaseSearch extends Vue {
  @Prop({ default: () => [] })
  readonly actions!: Array<IViewAction>;

  @Prop({ default: 'center' })
  readonly actionsAlign!: Allign

  @Prop({ default: 'flat' })
  readonly actionsStyle!: ActionStyle

  @Prop(String)
  readonly caption: string | undefined;

  @Prop({ default: 'auto' })
  readonly viewMode!: 'auto' | 'table' | 'list' | 'list-two-lines' | 'list-three-lines';

  @Prop({ default: () => [] })
  readonly headers!: Array<ITableHeader>;

  @Prop({ default: false })
  readonly loading!: boolean;

  @Prop({ default: false })
  readonly searching!: boolean;

  @Prop(String)
  readonly idKey: string | undefined;

  @Prop(String)
  readonly searchFilter: string | undefined;

  @Prop({ default: () => { return { itemsPerPage: 0, page: 1, append: false } } })
  readonly searchParams: ISearch<any, any> | undefined;

  common = $app.module(Common);
  system = $app.module(System);
  scroll: any = null;

  paginationLocal: IPagination = { sortBy: [], sortDesc: [], itemsPerPage: 0, page: 1, append: false };

  get pagination(): IPagination {
    this.paginationLocal.itemsPerPage = this.searchParams!.filter.itemsPerPage;
    this.paginationLocal.page = this.searchParams!.filter.page;
    this.paginationLocal.append = this.searchParams!.filter.append;
    this.paginationLocal.sortBy = this.searchParams!.filter.sortBy;
    this.paginationLocal.sortDesc = this.searchParams!.filter.sortDesc;
    return this.paginationLocal;
  }

  set pagination(value: IPagination) {
    if (
      value.itemsPerPage !== this.searchParams!.filter.itemsPerPage ||
      value.page !== this.searchParams!.filter.page ||
      value.sortBy !== this.searchParams!.filter.sortBy ||
      value.sortDesc !== this.searchParams!.filter.sortDesc ||
      value.append !== this.searchParams!.filter.append
    ) {
      this.paginationLocal = value;
      if (this.scroll && !this.isList) {
        this.scroll.scrollTop = 0;
      }
      this.$emit('search', value);
    }
  }

  get isList(): boolean {
    return (this.$vuetify.breakpoint.smAndDown && this.viewMode === 'auto') || this.viewMode.startsWith('list');
  }

  get rowsSelector(): any {
    return $app.tableOptions(this.searchParams!.filter.itemsPerPage);
  }

  onScroll(e: any) {
    this.scroll = e.target;
    const el: Element = this.$refs.itemsList as Element;
    if (
      el &&
      (this.searchParams!.filter.totals || 0) > (this.pagination.page * this.pagination.itemsPerPage) &&
      this.isList
    ) {
      if (e.target.scrollTop + e.target.offsetHeight >= el.clientHeight) {
        this.pagination.page++;
        this.pagination.append = true;
        this.$emit('search', this.pagination);
      }
    }
  }

  updateEnv() {
    this.common.$store.appSetViewTitle(this.caption ? $app.i18n(this.caption) : '');
  }

  updated() {
    this.updateEnv();
  }

  mounted() {
    this.updateEnv();
  }
}
